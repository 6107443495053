const Home = () => import("./views/Home.vue");
const Yonet = () => import("./views/Yonet.vue");
const List = () => import("./views/List.vue");

export default (router) => {
  router.addRoute("Main", {
    path: "/ourvictories",
    component: Home,
    redirect: "/ourvictories/list",
    children: [
      {
        path: "list",
        component: List,
      },
      {
        path: "yonet",
        component: Yonet,
      },
    ]
  });
};
