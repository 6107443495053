const HomeComponent = () => import("./views/home.vue");
const PackageComponent = () => import("./views/product.vue");
const DetailComponent = () => import("./views/Detail.vue");
const ListComponent = () => import("./views/List.vue");
const StepOne = () => import("./views/comps/Stepone.vue");
const StepTwo = () => import("./views/comps/Steptwo.vue");
const StepThree = () => import("./views/comps/Stepthree.vue");
const StepFour = () => import("./views/comps/Stepfour.vue");
const FinalStep = () => import("./views/comps/FinalStep.vue");


export default (router) => {
  router.addRoute("Main", {
    path: "/packages",
    component: HomeComponent,
    redirect: '/packages/list',
    children: [
      {
        path: "add",
        component: PackageComponent,
        redirect: "add/1",
        children: [
          {
            path: "1",
            component: StepOne,
          },
          {
            path: "2",
            component: StepTwo,
          },
          {
            path: "3",
            component: StepThree,
          },
          {
            path: "4",
            component: StepFour,
          },
          {
            path: "final",
            component: FinalStep,
          },
        ]
      },
      {
        path: 'detail/:id',
        component: DetailComponent,
      },
      {
        path: "list",
        component: ListComponent,
      }
    ]
  });
};
