enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  UPDATE_USER = "updateUser",
  FORGOT_PASSWORD = "forgotPassword",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",
  LOAD_BRANDS = "loadbrands",
  LOAD_ORDERED_BRANDS = "loadorderedbrands",
  LOAD_STUDENTS = "loadstudents",
  SAVE_BRAND = "savebrand",
  UPDATE_BRAND = "updatebrand",
  DELETE_BRAND = "deletebrand",
  SAVE_STUDENT = "savestudent",
  LOAD_PRODUCTS = "loadproducts",
  LOAD_ORDERED_PRODUCTS = "loadorderedproducts",
  UPDATE_PRODUCT = "updateproduct",
  DELETE_PRODUCT = "deleteproduct",
  DELETE_BADGE = "deletebadge",
  LOAD_BADGES = "loadbadges",
  SAVE_BADGE = "savebadge",
  SAVE_PRODUCT = "saveproduct",
  UPDATE_BADGE = "updatebadge",
  LOAD_CITIES = "loadcities",
  SAVE_CITIES = "savecities",
  UPDATE_CITIES = "updatecities",
  DELETE_CITIES = "deletecities",
  LOAD_ORDERED_CITIES = "loadorderedcities",
  SAVE = "save",
  UPDATE = "update",
  DELETE = "delete",
  LOAD = "load",


}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",
  LOAD_BRANDS_MUTATION = "loadbrandsMutation",
  LOAD_ORDERED_BRANDS_MUTATION = "loadorderedbrandsMutation",
  LOAD_LINKS_MUTATION = "loadlinksMutation",
  LOAD_METAS_MUTATION = "loadmetasMutation",
  SAVE_BRAND_MUTATION = "savebrandmutation",
  LOAD_STUDENTS_MUTATION = "loadstudentsMutation",
  SAVE_STUDENTS_MUTATION = "savestudentmutation",
  LOAD_PRODUCTS_MUTATION = "loadproductmutation",
  LOAD_ORDERED_PRODUCTS_MUTATION = "loadorderedproductmutation",
  SAVE_PRODUCT_MUTATION = "saveproducmutation",
  SET_PRODUCT = "setproduct",
  LOAD_BADGES_MUTATION = "loadbadgesmutation",
  SAVE_BADGES_MUTATION = "savebadgemutation",
  LOAD_CITIES_MUTATION = "loadcitiesmutation",
  LOAD_ORDERED_CITIES_MUTATION = "loadorderedcitiesmutation",
  SAVE_CITY_MUTATION = "savecitymutation",
  CHANGE_PACKAGE = "change_package",
  RESET_PACKAGE = "resetpackage",
}

export { Actions, Mutations };
