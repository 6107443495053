import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { AxiosRequestConfig } from "axios";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface City {
  cityId: number;
  title: string;
  active: number;
}


@Module
class CityModule extends VuexModule {
  cities: City[] = [];

  /**
   * @returns Single city
   */
  get city() {
    return (index) => {
      return this.cities[index] as City;
    };
  }


  /**
   * @returns All cities
   */

  get allCities() {
    return this.cities;
  }

  @Mutation
  [Mutations.LOAD_CITIES_MUTATION](payload) {
    this.cities = payload;
  }

  @Mutation
  [Mutations.LOAD_ORDERED_CITIES_MUTATION](payload) {
    this.cities = payload;
  }

  @Mutation
  [Mutations.SAVE_CITY_MUTATION](payload) {
    this.cities.push(payload);
  }

  @Action
  [Actions.LOAD_CITIES](payload) {
    const params = {
      params: payload,
    } as AxiosRequestConfig;
    return ApiService.query("/cities", params)
      .then(({ data }) => {
        if (data.success) {
          this.context.commit(Mutations.LOAD_CITIES_MUTATION, data.data);
          this.context.commit(Mutations.LOAD_LINKS_MUTATION, data.links);
          this.context.commit(Mutations.LOAD_METAS_MUTATION, data.meta);
        }
      })
      .catch(({ response }) => {
        console.log(response);
      });
  }

  @Action
  [Actions.LOAD_ORDERED_CITIES]() {
    return ApiService.get("/cities")
      .then(({ data }) => {
        console.log(data);
        if (data.success) {
          this.context.commit(Mutations.LOAD_CITIES_MUTATION, data.data);
        }
      })
      .catch(({ response }) => {
        console.log(response);
      });
  }

  @Action
  [Actions.SAVE_CITIES](payload) {
    const url = "/cities";
    return ApiService.post(url, payload);
  }


  @Action
  [Actions.UPDATE_CITIES](payload) {
    const url = "/cities";
    return ApiService.put(url, payload);
  }

  @Action
  [Actions.DELETE_CITIES](payload) {
    const url = "/cities/" + payload;
    return ApiService.delete(url);
  }
}


export default CityModule;