import { createApp } from "vue";
import App from "./App.vue";
import { registerModules } from "./register-module.js";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router/clean";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";


import 'element-plus/dist/index.css'


import raporModule from "@/modules/raporlar";
import dashboardModule from "@/modules/dashboard";
import videoModule from "@/modules/videos";
import lectureModule from "@/modules/lectures";
import topicModule from "@/modules/topics";
import usersModulde from "@/modules/users";
import educatorModule from "@/modules/educators";
import markaModule from "@/modules/brands";
import ogrenciModule from "@/modules/students";
import badgeModule from "@/modules/badges";
import packageModule from "@/modules/packages";
import cityModule from "@/modules/cities";
import ourvictoriesModule from "@/modules/ourvictories";
import schoolModule from "@/modules/schools";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";


import Vue3Lottie from 'vue3-lottie'

import vueVimeoPlayer from 'vue-vimeo-player';


import 'animate.css';
import 'vue3-lottie/dist/style.css';
import "@/core/plugins/prismjs";
import "bootstrap";
import mitt from 'mitt';





const emitter = mitt();

const app = createApp(App);

registerModules({
  dashboard: dashboardModule,
  raporlar: raporModule,
  lectures: lectureModule,
  topics: topicModule,
  users: usersModulde,
  videos: videoModule,
  educators: educatorModule,
  markalar: markaModule,
  ogrenciler: ogrenciModule,
  product: packageModule,
  badge: badgeModule,
  city: cityModule,
  ourvictory: ourvictoriesModule,
  school: schoolModule
});

app.use(Vue3Lottie);
app.use(vueVimeoPlayer)
app.use(store);
app.use(router);
app.use(ElementPlus);
app.provide('emitter', emitter);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
