const ID_TOKEN_KEY = "id_token" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {

  const a = window.localStorage.getItem(ID_TOKEN_KEY);

  console.log("TOKEN GELİYOR MU??");
  console.log(a);

  return a;
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  console.log("TOKEN LOCALE KAYDEDİlDİ");
  console.log(token);
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  console.log("TOKEN DESTROY EDİLDİ");
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken };
