import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { AxiosRequestConfig } from "axios";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";




@Module
class PackageModule extends VuexModule {
    package = {
        title: '',
        explanation: '',
        lessons: [],
        status: 'INACTIVE', //DRAFT, PUBLISHED, SCHEDULED
        publishDate: new Date(),
        meta:
        {
            title: 'Meta Başlık',
            description: 'Meta Açıklama',
            keywords: []
        },
        categories: [],
        color: '#000000',
        unitPrice: 0,
        discountType: 'NO_DISCOUNT',
        discountPrice: 0,
        discountPercent: 0,
        taxPercent: 0,
        sku: '',
        totalPrice: 0,
    };

    get packageItem() {
        return this.package;
    }
    @Mutation
    [Mutations.CHANGE_PACKAGE](payload) {
        this.package = payload;
    }
    @Mutation
    [Mutations.RESET_PACKAGE](payload) {
        this.package = {
            title: '',
            explanation: '',
            lessons: [],
            status: 'INACTIVE', //DRAFT, PUBLISHED, SCHEDULED
            publishDate: new Date(),
            meta:
            {
                title: 'Meta Başlık',
                description: 'Meta Açıklama',
                keywords: []
            },
            categories: [],
            color: '#000000',
            unitPrice: 0,
            discountType: 'NO_DISCOUNT',
            discountPrice: 0,
            discountPercent: 0,
            taxPercent: 0,
            sku: '',
            totalPrice: 0,
        };
    }

}


export default PackageModule;