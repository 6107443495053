import { User } from "@/store/modules/AuthModule";

const USER_KEY = "user_info" as string;

/**
 * @description get token form localStorage
 */
export const getUser = (): User | null => {

    const a = window.localStorage.getItem(USER_KEY);
    if (a != null) {
        return JSON.parse(a);
    }
    else {
        return null;
    }
};

/**
 * @description save token into localStorage
 * @param user: string
 */
export const saveUser = (user: User): void => {

    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
};

/**
 * @description remove token form localStorage
 */
export const destroyUser = (): void => {
    window.localStorage.removeItem(USER_KEY);
};

export default { getUser, saveUser, destroyUser };
