import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { AxiosRequestConfig } from "axios";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Brand {
  brandId: number;
  brandName: string;
  slogan: string;
  tag: string;
  color: string;
  image: string;
  explanation: string;
  content: string;
}




@Module
class BrandModule extends VuexModule {
  brands: Brand[] = [];




  links = {};
  metas = {};

  /**
   * @returns Single brand
   */

  get brand() {
    return (index) => {
      return this.brands[index] as Brand;
    };
  }

  /**
   * @returns Single brand
   */

  get metaObject() {
    return this.metas;
  }

  /**
   * @returns Single brand
   */

  get linkObject() {
    return this.links;
  }
  /**
   * @returns All brands
   */

  get allBrands() {
    return this.brands;
  }

  @Mutation
  [Mutations.LOAD_BRANDS_MUTATION](payload) {
    this.brands = payload;
  }

  @Mutation
  [Mutations.LOAD_ORDERED_BRANDS_MUTATION](payload) {
    this.brands = payload;
  }



  @Mutation
  [Mutations.LOAD_LINKS_MUTATION](payload) {
    this.links = payload;
  }
  @Mutation
  [Mutations.LOAD_METAS_MUTATION](payload) {
    this.metas = payload;
  }
  @Mutation
  [Mutations.SAVE_BRAND_MUTATION](payload) {
    this.brands.push(payload);
  }

  @Action
  [Actions.LOAD_BRANDS](payload) {
    const params = {
      params: payload,
    } as AxiosRequestConfig;
    return ApiService.query("/brands", params)
      .then(({ data }) => {

        if (data.success) {
          this.context.commit(Mutations.LOAD_BRANDS_MUTATION, data.data);
          this.context.commit(Mutations.LOAD_LINKS_MUTATION, data.links);
          this.context.commit(Mutations.LOAD_METAS_MUTATION, data.meta);
        }
      })
      .catch(({ response }) => {
        console.log(response);
      });
  }


  @Action
  [Actions.LOAD_ORDERED_BRANDS]() {
    return ApiService.get("/brands")
      .then(({ data }) => {
        console.log(data);
        if (data.success) {
          this.context.commit(Mutations.LOAD_BRANDS_MUTATION, data.data);
        }
      })
      .catch(({ response }) => {
        console.log(response);
      });
  }

  @Action
  [Actions.SAVE_BRAND](payload) {
    const url = "/brands";
    return ApiService.post(url, payload);
  }


  @Action
  [Actions.UPDATE_BRAND](payload) {
    const url = "/brands";
    return ApiService.put(url, payload);
  }

  @Action
  [Actions.DELETE_BRAND](payload) {
    const url = "/brands/" + payload;
    return ApiService.delete(url);
  }
}


export default BrandModule;