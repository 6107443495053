import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { AxiosRequestConfig } from "axios";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Badge {
  badgeId: number;
  badgeKey: string;
  title: string;
  image: string;
  explanation: string;
  type: string;
  seq: number;
}


@Module
class BadgeModule extends VuexModule {
  badges: Badge[] = [];

  /**
   * @returns Single badge
   */

  get badge() {
    return (index) => {
      return this.badges[index] as Badge;
    };
  }

  /**
   * @returns All Badges
   */

  get allBadges() {
    return this.badges;
  }

  @Mutation
  [Mutations.LOAD_BADGES_MUTATION](payload) {
    this.badges = payload;
  }


  @Mutation
  [Mutations.SAVE_BADGES_MUTATION](payload) {
    this.badges.push(payload);
  }

  @Action
  [Actions.LOAD_BADGES](payload) {
    const params = {
      params: payload,
    } as AxiosRequestConfig;
    return ApiService.query("/badges", params)
      .then(({ data }) => {

        if (data.success) {
          this.context.commit(Mutations.LOAD_BADGES_MUTATION, data.data);
          this.context.commit(Mutations.LOAD_LINKS_MUTATION, data.links);
          this.context.commit(Mutations.LOAD_METAS_MUTATION, data.meta);
        }
      })
      .catch(({ response }) => {
        console.log(response);
      });
  }


  @Action
  [Actions.SAVE_BADGE](payload) {
    const url = "/badges";
    return ApiService.post(url, payload);
  }


  @Action
  [Actions.UPDATE_BADGE](payload) {
    const url = "/badges";
    return ApiService.put(url, payload);
  }

  @Action
  [Actions.DELETE_BADGE](payload) {
    const url = "/badges/" + payload;
    return ApiService.delete(url);
  }
}


export default BadgeModule;