import JwtService from "@/core/services/JwtService";
import store from "@/store";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { nextTick } from "@vue/runtime-core";
import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from "vue-router";



const routes = [
  {
    path: "/",
    name: "Main",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
];


const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  //store.commit(Mutations.RESET_LAYOUT_CONFIG);

  //store.dispatch(Actions.VERIFY_AUTH, { api_token: JwtService.getToken() });

  // Scroll page to top on every route change
  // setTimeout(() => {
  //   window.scrollTo(0, 0);
  // }, 100);
});

export default router;
