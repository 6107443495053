const Home = () => import("./views/Home.vue");
const WebAnalytic = () => import("./views/Webanalytic.vue");
const StoreAnalytic = () => import("./views/StoreAnalytic.vue");
const FinancePerformance = () => import("./views/FinancePerformance.vue");

export default (router) => {
  router.addRoute("Main", { path: "/dashboard", component: Home });
  router.addRoute("Main", { path: "/web-site-analytics", component: WebAnalytic });
  router.addRoute("Main", { path: "/store-analytics", component: StoreAnalytic });
  router.addRoute("Main", { path: "/finance-performance", component: FinancePerformance });
};
