import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { AxiosRequestConfig } from "axios";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Product {
  productId: number;
  sku: string;
  title: string;
  explanation: string;
  image: string;
  color: string;
  icon: string;
  status: string;
}




@Module
class ProductModule extends VuexModule {
  products: Product[] = [];
  product = {};

  /**
   * @returns Single Product
   */

  // get productItem() {
  //   return (index) => {
  //     return this.products[index] as Product;
  //   };
  // }

  get productItem() {
    return this.product;
  }




  get allProducts() {
    return this.products;
  }

  @Mutation
  [Mutations.LOAD_PRODUCTS_MUTATION](payload) {
    this.products = payload;
  }

  @Mutation
  [Mutations.LOAD_ORDERED_PRODUCTS_MUTATION](payload) {
    this.products = payload;

  }
  @Mutation
  [Mutations.SET_PRODUCT](payload) {
    //console.log(payload);
    this.product[payload.key] = payload.value;
  }

  @Mutation
  [Mutations.SAVE_PRODUCT_MUTATION](payload) {
    this.products.push(payload);
  }

  @Action
  [Actions.LOAD_PRODUCTS](payload) {
    const params = {
      params: payload,
    } as AxiosRequestConfig;
    return ApiService.query("/products", params)
      .then(({ data }) => {
        if (data.success) {
          this.context.commit(Mutations.LOAD_PRODUCTS_MUTATION, data.data);
          this.context.commit(Mutations.LOAD_LINKS_MUTATION, data.links);
          this.context.commit(Mutations.LOAD_METAS_MUTATION, data.meta);
        }
      })
      .catch(({ response }) => {
        console.log(response);
      });
  }


  @Action
  [Actions.LOAD_ORDERED_PRODUCTS]() {
    ApiService.get("/products")
      .then(({ data }) => {
        console.log(data);
        if (data.success) {
          this.context.commit(Mutations.LOAD_PRODUCTS_MUTATION, data.data);

        }
      })
      .catch(({ response }) => {
        console.log(response);
      });
  }

  @Action
  [Actions.SAVE_PRODUCT](payload) {
    const url = "/products";
    return ApiService.post(url, payload);
  }


  @Action
  [Actions.UPDATE_PRODUCT](payload) {
    const url = "/products";
    return ApiService.put(url, payload);
  }

  @Action
  [Actions.DELETE_PRODUCT](payload) {
    const url = "/products/" + payload;
    return ApiService.delete(url);
  }
}


export default ProductModule;