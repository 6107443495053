import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { AxiosRequestConfig } from "axios";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class CrudModule extends VuexModule {


    @Action
    [Actions.SAVE](payload) {
        const url = payload.slug;
        return ApiService.post(url, payload.data);
    }


    @Action
    [Actions.UPDATE](payload) {
        const url = payload.slug;
        return ApiService.put(url, payload.data);
    }

    @Action
    [Actions.DELETE](payload) {
        const url = payload.slug;
        return ApiService.delete(url);
    }


    @Action
    [Actions.LOAD](payload) {
        console.log("queeeriess ");
        console.log(payload);
        const params = {
            params: payload.queries,
        } as AxiosRequestConfig;
        return ApiService.query(payload.slug, params);

    }

    // @Action
    // [Actions.LOAD](slug, queries) {
    //     const params = {
    //         params: queries,
    //     } as AxiosRequestConfig;
    //     return ApiService.query(slug, params)
    //         .then(({ data }) => {

    //             if (data.success) {
    //                 this.context.commit(Mutations.LOAD_BRANDS_MUTATION, data.data);
    //                 this.context.commit(Mutations.LOAD_LINKS_MUTATION, data.links);
    //                 this.context.commit(Mutations.LOAD_METAS_MUTATION, data.meta);
    //             }
    //         })
    //         .catch(({ response }) => {
    //             console.log(response);
    //         });
    // }
}
