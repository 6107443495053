import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import BrandModule from "@/store/modules/BrandModule";
import ProductModule from "@/store/modules/ProductModule";
import BadgeModule from "@/store/modules/BadgeModule";
import CityModule from "@/store/modules/CityModule";
import CrudModule from "@/store/modules/CrudModule";
import PackageModule from "@/store/modules/PackageModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    BrandModule,
    ProductModule,
    BadgeModule,
    CityModule,
    CrudModule,
    PackageModule
  },
});

export default store;
